@import "vars";
@import "header";
.app-admin-wrap {
    width: 100%;
}
.layout-horizontal-bar {
    .main-content-wrap {
        width: 100%;
        float: right;
        margin-top: $topbar-height;
        transition: width 0.24s ease-in-out;
        padding: 2rem 2rem 0;
        position: relative;
        min-height: calc(100vh - #{$topbar-height});
        background: $white;
        &.sidenav-open {
            width: calc(100% - #{$sidebar-left-width});
        }
    }

    .module-loader {
        position: fixed;
        background: rgba($background, 0.5);
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9;
        .loader,
        .spinner {
            position: fixed;
            top: 45%;
            left: calc(50% + 56px);
            z-index: inherit;
        }
    }
}
@media (max-width: 767px) {
    .layout-horizontal-bar {
    }
}

@media (max-width: 576px) {
    .layout-horizontal-bar {
        .main-content-wrap {
            padding: 1.5rem;
            &.sidenav-open {
                width: 100%;
            }
        }
        .main-content-wrap {
            margin-top: $topbar-height-mobile;
        }
    }
}

[dir="rtl"] {
    .layout-horizontal-bar {
        .sidebar-left {
            left: auto !important;
            right: calc(-#{$sidebar-left-width} - #{$side-clearance});
            &.open {
                left: auto !important;
                right: 0;
            }
            .navigation-left .nav-item .triangle {
                transform: rotate(90deg);
                right: auto;
                left: 0;
            }
        }
        .sidebar-left-secondary {
            left: auto !important;
            right: calc(-#{$sidebar-left-secondary-width} - #{$side-clearance});
            &.open {
                left: auto !important;
                right: $sidebar-left-width;
            }
            .childNav li.nav-item a {
                .dd-arrow {
                    margin-left: unset !important;
                    margin-right: auto;
                }
                .nav-icon {
                    margin-left: 8px;
                    margin-right: 0;
                }
            }
        }
        .main-content-wrap {
            float: left;
        }
        .sidebar-overlay {
            right: auto !important;
            left: 0;
            cursor: e-resize;
        }
    }
}
