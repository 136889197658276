// Paths
$pws_paths: (
	"fonts": "../webfonts/",
	"images": "../images/",
	"flags": "../flags",
	"uploads": "../../storage/",
);

$flag-icon-css-path: pws_path("flags");

$font-family-heading: 'Nunito', sans-serif;
$font-family-base: 'Nunito', sans-serif;
$font-size-base: .813rem;

$blue: #00509E;
$white: #ffffff;
$border-radius: 0.25rem;
$gray-200: #f8f9fa;
$gray-600: #ced4da;
$gray-600: #ced4da;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$btn-primary-bg: #00509E;
$btn-primary-color: #00509E;
$border-radius-base: .25rem;
$gray-lighter: #f8f9fa;
$gray-light: #ced4da;

$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;


// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;

$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram:rgb(193,53,132);
$linkedin:rgb(0,119,181);
$dribble:#ea4c89;
$youtube: #c4302b;
