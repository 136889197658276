.sidebar-container {
    position: relative;
    overflow: hidden;
    min-height: 400px;
    .sidebar-content {
        position: relative;
        height: 100%;
        transition: all .3s ease-in;
        &:after {
            position: absolute;
            content: "";
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
    .sidebar {
        position: absolute;
        top: 0;
        transition: all .3s ease-in;
        z-index: 60;
        .sidebar-close {
            display: none;
            position: absolute;
            top: 4px;
            right: 4px;
            cursor: pointer;
            z-index: 999;
        }
    }
}

@media (max-width: 767px) {
    .sidebar-container {
        .sidebar {
            background: $background;
            .sidebar-close {
                display: block;
            }
        }
    }
}
